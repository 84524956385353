$(document).on('page:load', function() {

  $("#datepicker ,#datepicker_")
    .datepicker({
      autoclose: true,
      todayHighlight: true,
    })

    $(function () {
      $('._nav_link  a').filter(function () {
        return this.href == location.href
      }).parent().addClass('active_tab').siblings().removeClass('active_tab')
      $('._nav_link  a').click(function () {
          $(this).parent().addClass('active_tab').siblings().removeClass('active_tab')
      })
    })

});
